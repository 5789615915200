import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2, H5 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import PatternSvg from 'components/svg/patternSvg';
import { defineMessages } from 'react-intl';
import { ShIntlMessageDefinition, ShText } from '@shoootin/translations';
import { getFrontMessageUnsafe } from '../../appMessageDefinitions';
import { HLNumber } from '../../components/highlights';
import { range } from 'lodash';

const frontLegalPrefix = 'front_legal';
const FrontLegalMessages = defineMessages({
  title: {
    id: `${frontLegalPrefix}_title`,
    defaultMessage: 'Legal notices',
  },
});

const LegalLength = 5; // from Backend

export const getLegalMessages = (
  i: HLNumber,
): {
  title: ShIntlMessageDefinition;
  description: ShIntlMessageDefinition;
} => ({
  title: getFrontMessageUnsafe(`${frontLegalPrefix}_title_${i}`),
  description: getFrontMessageUnsafe(`${frontLegalPrefix}_description_${i}`),
});

const LegalPage = () => {
  return (
    <MainLayout className="page-faq light" pageName="legal">
      <AppSection
        className="faq gradient-tt"
        header={
          <span>
            <H2>
              <span>
                <ShText message={FrontLegalMessages.title} />
              </span>
              <PatternSvg />
            </H2>
          </span>
        }
      >
        <div css={{ marginTop: 50 }}>
          {range(LegalLength).map((i) => (
            <div key={i}>
              <H5>
                <ShText message={getLegalMessages(i).title} />
              </H5>
              <div css={{ marginBottom: 30 }}>
                <ShText message={getLegalMessages(i).description} />
              </div>
            </div>
          ))}
        </div>
      </AppSection>
    </MainLayout>
  );
};

export default LegalPage;
